import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { ApiMe } from "../../service/api";
import { analytics } from "../../util/CustomAnalitics";

interface IUserContext {
  user: IUser | null;
  setUser: (user: IUser | null) => void;
}

const UserContext = createContext<IUserContext | null>(null);

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) return { user: null, setUser: null };
  return context;
};

export const UserProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);

  const getMe = async () => {
    try {
      if (localStorage.getItem("user")) {
        const data = JSON.parse(localStorage.getItem("user") ?? "");
        setUser(data);
        
        analytics.identify(data.user.uuid, {
          name: data.owner.fullName,
          email: data.user.email,
        });
      } else {
        const { data } = await ApiMe();
        localStorage.setItem("user", JSON.stringify(data));
        setUser(data);

        analytics.identify(data.user.uuid, {
          name: data.owner.fullName,
          email: data.user.email,
        });
      }
    } catch (error) {
      console.error("Error al obtener usuario:", error);
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
