exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-business-index-tsx": () => import("./../../../src/pages/admin/business/index.tsx" /* webpackChunkName: "component---src-pages-admin-business-index-tsx" */),
  "component---src-pages-admin-campaign-birthday-club-tsx": () => import("./../../../src/pages/admin/campaign/birthday-club.tsx" /* webpackChunkName: "component---src-pages-admin-campaign-birthday-club-tsx" */),
  "component---src-pages-admin-campaign-index-tsx": () => import("./../../../src/pages/admin/campaign/index.tsx" /* webpackChunkName: "component---src-pages-admin-campaign-index-tsx" */),
  "component---src-pages-admin-campaign-lapsed-customers-tsx": () => import("./../../../src/pages/admin/campaign/lapsed-customers.tsx" /* webpackChunkName: "component---src-pages-admin-campaign-lapsed-customers-tsx" */),
  "component---src-pages-admin-cancel-tsx": () => import("./../../../src/pages/admin/cancel.tsx" /* webpackChunkName: "component---src-pages-admin-cancel-tsx" */),
  "component---src-pages-admin-comm-index-tsx": () => import("./../../../src/pages/admin/comm/index.tsx" /* webpackChunkName: "component---src-pages-admin-comm-index-tsx" */),
  "component---src-pages-admin-comm-message-tsx": () => import("./../../../src/pages/admin/comm/message.tsx" /* webpackChunkName: "component---src-pages-admin-comm-message-tsx" */),
  "component---src-pages-admin-comm-recipient-tsx": () => import("./../../../src/pages/admin/comm/recipient.tsx" /* webpackChunkName: "component---src-pages-admin-comm-recipient-tsx" */),
  "component---src-pages-admin-comm-review-tsx": () => import("./../../../src/pages/admin/comm/review.tsx" /* webpackChunkName: "component---src-pages-admin-comm-review-tsx" */),
  "component---src-pages-admin-comm-send-tsx": () => import("./../../../src/pages/admin/comm/send.tsx" /* webpackChunkName: "component---src-pages-admin-comm-send-tsx" */),
  "component---src-pages-admin-dashboard-tsx": () => import("./../../../src/pages/admin/dashboard.tsx" /* webpackChunkName: "component---src-pages-admin-dashboard-tsx" */),
  "component---src-pages-admin-employee-tsx": () => import("./../../../src/pages/admin/employee.tsx" /* webpackChunkName: "component---src-pages-admin-employee-tsx" */),
  "component---src-pages-admin-member-index-tsx": () => import("./../../../src/pages/admin/member/index.tsx" /* webpackChunkName: "component---src-pages-admin-member-index-tsx" */),
  "component---src-pages-admin-member-profile-[uuid]-tsx": () => import("./../../../src/pages/admin/member/profile/[uuid].tsx" /* webpackChunkName: "component---src-pages-admin-member-profile-[uuid]-tsx" */),
  "component---src-pages-admin-stamp-[uuid]-tsx": () => import("./../../../src/pages/admin/stamp/[uuid].tsx" /* webpackChunkName: "component---src-pages-admin-stamp-[uuid]-tsx" */),
  "component---src-pages-admin-stamp-create-appearance-tsx": () => import("./../../../src/pages/admin/stamp/create/appearance.tsx" /* webpackChunkName: "component---src-pages-admin-stamp-create-appearance-tsx" */),
  "component---src-pages-admin-stamp-create-index-tsx": () => import("./../../../src/pages/admin/stamp/create/index.tsx" /* webpackChunkName: "component---src-pages-admin-stamp-create-index-tsx" */),
  "component---src-pages-admin-stamp-create-publish-tsx": () => import("./../../../src/pages/admin/stamp/create/publish.tsx" /* webpackChunkName: "component---src-pages-admin-stamp-create-publish-tsx" */),
  "component---src-pages-admin-stamp-create-review-tsx": () => import("./../../../src/pages/admin/stamp/create/review.tsx" /* webpackChunkName: "component---src-pages-admin-stamp-create-review-tsx" */),
  "component---src-pages-admin-stamp-create-reward-tsx": () => import("./../../../src/pages/admin/stamp/create/reward.tsx" /* webpackChunkName: "component---src-pages-admin-stamp-create-reward-tsx" */),
  "component---src-pages-admin-stamp-index-tsx": () => import("./../../../src/pages/admin/stamp/index.tsx" /* webpackChunkName: "component---src-pages-admin-stamp-index-tsx" */),
  "component---src-pages-admin-success-tsx": () => import("./../../../src/pages/admin/success.tsx" /* webpackChunkName: "component---src-pages-admin-success-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */)
}

