import "@fontsource/manrope";
import "@fontsource/manrope/400.css";
import "@fontsource/manrope/600.css";
import "@fontsource/manrope/700.css";
import "./src/styles/global.scss";

import React from "react";
import MiddlewareAdminRoute from "./src/components/middleware/MiddlewareAdminRoute";
import { UserProvider } from "./src/components/admin/UserContext";
import { analytics } from "./src/util/CustomAnalitics";

export const wrapPageElement = ({ element, props }) => {
  const isAdminRoute = props.location.pathname.startsWith("/admin");

  if (isAdminRoute)
    return (
      <UserProvider>
        <MiddlewareAdminRoute>{element}</MiddlewareAdminRoute>
      </UserProvider>
    );

  return element;
};

export const onInitialClientRender = () => {
  analytics.page();
  const storedUser = JSON.parse(localStorage.getItem("user"));
  if (storedUser) {
    analytics.identify(storedUser.user.uuid, {
      name: storedUser.owner.fullName,
      email: storedUser.user.email,
    });
  }
};

export const onRouteUpdate = ({ location }) => {
  analytics.page({
    path: location.pathname,
    search: location.search,
    hash: location.hash,
  });

  const storedUser = JSON.parse(localStorage.getItem("user"));
  if (storedUser) {
    analytics.identify(storedUser.user.uuid, {
      name: storedUser.owner.fullName,
      email: storedUser.user.email,
    });
  }
};
