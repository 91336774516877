export const ApiSignUp = async (values: {
  email: string;
  password: string;
  fullName: string;
  phone: string;
  business: string;
  businessType: string;
}) => {
  const nValues = {
    user: {
      email: values.email,
      password: values.password,
      phone: values.phone.replaceAll(" ", ""),
    },
    business: { name: values.business, type: values.businessType },
    owner: { fullName: values.fullName },
  };

  const response = await fetch(`${process.env.API_URL}/auth/sign-up`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(nValues),
  });

  return await response.json();
};

export const ApiSignIn = async (values: {
  username: string;
  password: string;
  role?: string;
}) => {
  values.role = "business";
  const response = await fetch(`${process.env.API_URL}/auth/sign-in`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(values),
  });

  return await response.json();
};

export const ApiMe = async () => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/auth/me`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return await response.json();
};

export const ApiStatistics = async (filterType = "year") => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/dashboard/statistics?filterType=${filterType}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return await response.json();
};

export const ApiDashboardStamp = async (query?: string) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/dashboard/stamp${query ? "?" + query : ""}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return await response.json();
};

export const ApiDashboardStampChart = async (filterType = "year") => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/dashboard/chart/stamp?filterType=${filterType}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return await response.json();
};

export const ApiDashboardRedemption = async (query: any) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/dashboard/redemption${query ? "?" + query : ""}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return await response.json();
};

export const ApiDashboardRedemptionChart = async (filterType = "year") => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/dashboard/chart/redemption?filterType=${filterType}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return await response.json();
};

export const ApiBranch = async (query?: string) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/branch${query ? "?" + query : ""}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return await response.json();
};

export const ApiBranchStore = async (values: {
  name: string;
  address: string;
}) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/branch`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(values),
  });

  return await response.json();
};

export const ApiBranchUpdate = async (values: {
  uuid?: string;
  name: string;
  address: string;
}) => {
  const token = localStorage.getItem("token") ?? "";
  const { name, address } = values;
  const response = await fetch(`${process.env.API_URL}/branch/${values.uuid}`, {
    method: "PATCH",
    headers: {
      accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ name, address }),
  });
  return await response.json();
};

export const ApiBranchDelete = async (uuid: string) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/branch/${uuid}`, {
    method: "DELETE",
    headers: {
      accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
};

export const ApiSocial = async () => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/social-network`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return await response.json();
};

export const ApiSocialStore = async (values: {
  key: string;
  value: string;
}) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/social-network`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(values),
  });

  return await response.json();
};

export const ApiSocialUpdate = async (values: {
  key: string;
  value: string;
}) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/social-network/${values.key}`,
    {
      method: "PATCH",
      headers: {
        accept: "application/json",
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ value: values.value }),
    }
  );

  return await response.json();
};

export const ApiSocialDelete = async (key: string) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/social-network/${key}`, {
    method: "DELETE",
    headers: { Authorization: `Bearer ${token}` },
  });

  return await response.json();
};

export const ApiSocialBusinessName = async (values: { socialName: string }) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/business`, {
    method: "PATCH",
    headers: {
      accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(values),
  });

  return await response.json();
};

export const ApiSocialBusinessIcon = async (formData: FormData) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/business/icon`, {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
    body: formData,
  });

  return await response.json();
};

export const ApiPlan = async (query?: string) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/plan${query ? "?" + query : ""}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return await response.json();
};

export const ApiPlanSubscription = async (uuid: string) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/plan/${uuid}/subscription`,
    { method: "POST", headers: { Authorization: `Bearer ${token}` } }
  );

  return await response.json();
};

export const ApiAuthGoogle = async (accessToken: string) => {
  const response = await fetch(`${process.env.API_URL}/auth/google`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify({ accessToken }),
  });

  return await response.json();
};
